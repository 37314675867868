
import { defineComponent, ref } from 'vue';
import CircleIconButton from '@/components/common/buttons/CircleIconButton.vue';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'PreviewVideo',
  components: { CircleIconButton },
  emits: ['stopConnection', 'clickPlus', 'close', 'selectPgm'],
  props: {
    isConnected: {
      default: false,
      type: Boolean,
    },
    isShowSettings: {
      default: false,
      type: Boolean,
    },
    isShowChangeCam: {
      default: false,
      type: Boolean,
    },
    active: {
      default: false,
      type: Boolean,
    },
    flipVideo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    camera: {
      type: Number,
      default: null,
    },
    isEnlargedCard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const stopConnection = () => {
      if (props.disabled) return;
      emit('stopConnection');
    };
    const addConnection = () => {
      if (props.disabled) return;
      emit('clickPlus');
    };

    const settingsButtons = ref(null);

    onClickOutside(settingsButtons, () => emit('close'));

    const onClickVideo = (event: Event) => {
      emit('selectPgm');
    };

    return {
      stopConnection,
      addConnection,
      settingsButtons,
      onClickVideo,
    };
  },
});
