import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`button-rec button-rec_type-${_ctx.type}`, { disabled: _ctx.disabled }, { animate: _ctx.animateButton }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickBtn && _ctx.clickBtn(...args)))
  }, [
    (_ctx.type === 'start')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "rec"))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "STOP"))
  ], 2))
}