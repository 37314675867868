
import { defineComponent, ref, PropType } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import ButtonRec from '@/components/common/buttons/ButtonRec.vue';
import { NLPWebRTCConnector } from '@/utils/webrtc';

export default defineComponent({
  components: {
    Icon,
    ButtonRec,
  },
  name: 'VideoControls',
  emits: ['openControls', 'startRecord', 'stopRecord', 'showDeleteCams', 'switchLocalCam', 'toggleAi', 'vksToggle'],
  props: {
    cameraCount: {
      type: Number,
      default: 0,
    },
    typeButtonRec: {
      type: String,
      default: 'start',
    },
    conns: {
      type: Array as PropType<Array<NLPWebRTCConnector | MediaStream | undefined>>,
      default: () => [],
    },
    currentStep: {
      type: String,
      default: '',
    },
    soundState: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const isShowControls = ref(false);

    const openControls = (): void => {
      isShowControls.value = !isShowControls.value;
      emit('openControls', isShowControls.value);
    };

    const ai = ref(false);

    const toggleAi = () => {
      ai.value = !ai.value;
      emit('toggleAi', ai.value);
    };

    return {
      isShowControls,
      openControls,
      toggleAi,
      ai,
    };
  },
});
