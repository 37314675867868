import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6e2bba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "interactive-helper" }
const _hoisted_2 = { class: "interactive-helper__content" }
const _hoisted_3 = { class: "interactive-helper__text" }
const _hoisted_4 = { class: "interactive-helper__hand" }
const _hoisted_5 = { class: "interactive-helper__arrow left" }
const _hoisted_6 = { class: "interactive-helper__arrow right" }
const _hoisted_7 = { class: "interactive-helper__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ButtonVue = _resolveComponent("ButtonVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeArray, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'clock' + index
          }, _toDisplayString(item), 1))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Icon, { icon: "hand-top" })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Icon, { icon: "left-arrow-tip" })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Icon, { icon: "right-arrow-tip" })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ButtonVue, {
          text: "Понятно",
          size: "l",
          onClick: _ctx.close
        }, null, 8, ["onClick"])
      ])
    ])
  ]))
}