import { ref, computed, Ref } from 'vue';
import { NLPWebRTCConnector, NLPRtcConnectionSide } from '@/utils/webrtc';
import { NLPDestroyList } from '@/utils/destroyer';
import { useLocalRecorder } from '@/composables/useWebrtc';
import { NLPP2PEvents } from '@/utils/p2p-protocol-events';
import { sendMetric } from '@/utils/helpers';
import { NLPMediaRecorder } from '@/utils/mediarecorder';
import { api } from '@/utils/api';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import { NLPEditingEventType } from '@/utils/editinglist';
import { useVideoImage } from '@/composables/useVideoImage';
import { NLPVideoUpload } from '@/utils/videocapture';
import { NLPVKSDirectorMixer, NLPVKSOperatorMixer } from '@/utils/vks';

export function useVideoConnection({
  previevVideo,
  flipVideo,
  currentStep,
  stopAiTimer,
  editingList,
  isUploadVideo,
  typeButtonRec,
  isShowSettings,
}: any) {
  const conns: Ref<Array<NLPWebRTCConnector | MediaStream | undefined>> = ref([]);
  const destroyers: NLPDestroyList = new NLPDestroyList();
  const isConnectedLocalCam = ref<boolean>(false);
  // connectedCam
  const indexConnectedCam: Ref<number | undefined> = ref();
  const selectedCam: Ref<number | undefined> = ref();
  const isShowPgm: Ref<boolean> = ref(false);
  const isConnected: Ref<{ [key: string]: boolean }> = ref({ 1: false, 2: false, 3: false, 4: false });
  const remote_resolution = ref<Array<string>>([]);
  const dvrs: Ref<Array<NLPMediaRecorder | null>> = ref([]);
  const totalLength = ref<number[]>([]);
  const connectedLocalCamera = ref<number | null>(null);

  const vksMixer = new NLPVKSDirectorMixer();
  const vksOperatorMixer: Array<NLPVKSOperatorMixer> = [];
  const soundState: Ref<boolean> = ref(false);
  let localMicStream: MediaStream | null = null;
  let localMicDevice: string | undefined = undefined;
  let remoteAudioActiveCntr = 0;

  const router = useRouter();
  const store = useStore();
  const { createPreviewVideo } = useVideoImage();

  const {
    createRecorder,
    stopWriter: stopRecorder,
    startRecord: startLocationRecord,
    createWriter,
    destroyConn,
    setZoom,
    zoomParams,
  } = useLocalRecorder();

  const cameraCount = computed(() => {
    let cnt = 0;
    conns.value.forEach((conn) => {
      if (conn instanceof MediaStream) ++cnt;
      else if (conn instanceof NLPWebRTCConnector) {
        if (conn.valid && conn.connected) ++cnt;
      }
    });
    return cnt;
  });

  const getLocalMicTrack = async (audioInput?: string): Promise<MediaStreamTrack> => {
    let track;
    if (audioInput === localMicDevice && localMicStream !== null) {
      track = localMicStream.getAudioTracks()[0];
    }
    if (track !== undefined) return track;

    localMicStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        autoGainControl: true,
        noiseSuppression: true,
        deviceId: audioInput ? { exact: audioInput } : undefined,
      },
    });

    if (!localMicStream) throw new Error('Can not get audio track');

    track = localMicStream.getAudioTracks()[0];

    if (track === undefined) throw new Error('Can not get audio track');

    if (localMicDevice !== audioInput) {
      vksOperatorMixer.forEach((mixer) => {
        mixer.setupLocalStream(localMicStream);
      });
    }

    localMicDevice = audioInput;
    return track;
  };

  const setLocalMicTrack = (track?: MediaStreamTrack) => {
    if (track === undefined) return;
    localMicStream = new MediaStream();
    localMicDevice = track.getCapabilities().deviceId;
    localMicStream.addTrack(track);
    vksOperatorMixer.forEach((mixer) => {
      mixer.setupLocalStream(localMicStream);
    });
    for (const conn of conns.value) {
      if (conn instanceof NLPWebRTCConnector) {
        conn.changeAudioTrack(localMicStream);
      }
    }
  };

  const addOwnCamera = async ({
    videoInput,
    audioInput,
    facingMode,
  }: {
    videoInput: string | null;
    audioInput: string;
    facingMode?: string;
  }) => {
    // isShowDelete.value = false;
    // сохраняем последние значения
    window.localStorage.setItem('app.ownCamera', videoInput || '');
    window.localStorage.setItem('app.ownMic', audioInput);
    window.localStorage.setItem('app.ownFacing', facingMode || '');
    // isShowModalDevice.value = false;
    if (!indexConnectedCam.value) return;
    console.log('connect cam', indexConnectedCam.value);

    const cam = indexConnectedCam.value;
    if (!cam) return;
    connectedLocalCamera.value = cam;

    const stream = await createRecorder(cam, null, { videoInput, audioInput: '', facingMode });

    setLocalMicTrack(stream.getAudioTracks()[0]);

    console.log('stream', stream, stream.getTracks());

    connectVideo(cam, stream);
    if (conns.value[cam] instanceof NLPWebRTCConnector) {
      destroyers.destroyById(`update-remote-time-${cam}`);
      (conns.value[cam] as NLPWebRTCConnector).destroy();
    }
    conns.value[cam] = stream;
    destroyers.destroyFunc(destroyConn, `local-cam-${cam}`);
    if (!selectedCam.value) {
      selectedCam.value = cam;
      isShowPgm.value = true;
    }
    isConnectedLocalCam.value = true;
    indexConnectedCam.value = 0;

    // setTimeout(() => {
    //   isShowInteractiveHelper.value = true;
    // }, 4000);
  };

  async function connectVideo(cam: number, conn: NLPWebRTCConnector | MediaStream) {
    const video_chn = previevVideo.value[cam - 1]?.$refs.input_video;
    console.log('connect video', cam, video_chn);
    if (conn instanceof NLPWebRTCConnector) {
      video_chn.srcObject = await conn.getReceiveStream();
      // const video_pgm = pgm_video.value.$refs.video[cam - 1];
      // video_pgm.srcObject = await conn.getReceiveStream();
      destroyers.deleteById(`webrtc-${cam}`);
      flipVideo.value[cam] = false;
      const vksStream = await conn.getReceiveStream();
      vksMixer.addMediaStream(cam, vksStream);
      vksOperatorMixer.forEach((mixer) => {
        mixer.addCameraStream(cam, vksStream);
      });
    } else if (conn instanceof MediaStream) {
      video_chn.srcObject = conn;
      // const video_pgm = pgm_video.value.$refs.video[cam - 1];
      // video_pgm.srcObject = conn;
      destroyers.destroyById(`webrtc-${cam}`);
      const s = conn.getVideoTracks()[0]?.getSettings();
      flipVideo.value[cam] = s && s.facingMode === 'user';
    }

    video_chn.addEventListener(
      'loadedmetadata',
      () => {
        // проверить, нигде не используется
        remote_resolution.value[cam] = `${video_chn.videoWidth}x${video_chn.videoHeight}`;
      },
      {
        once: true,
      }
    );
    conns.value[cam] = conn;
    isConnected.value[cam] = true;
    if (!selectedCam.value) {
      selectedCam.value = cam;
      isShowPgm.value = true;
    }
    currentStep.value = '';
  }

  const destroyAllConnection = () => {
    conns.value.forEach((conn, index) => {
      if (conn instanceof NLPWebRTCConnector) {
        conn?.p2p.emitRemote(NLPP2PEvents.delete());
        conn.destroy();
        isConnected.value[index] = false;
        conn = undefined;
      } else if (conn instanceof MediaStream) {
        destroyConn();
      }
    });
  };

  const resultVideo = ref<Array<string>>([]);

  const sendInCloud = async () => {
    const cx = editingList.value.exportForCloud();

    const ids = await api().uploadEditingList(cx);
    if (ids !== null) editingList.value.updateIdsFromCloud(ids);
    // router.push('/producer-projects');
  };

  const finishRecord = async () => {
    // stopAiTimer();
    // typeButtonRec.value = 'start';
    sendMetric('web_stop');

    conns.value.forEach((item) => {
      if (item instanceof NLPWebRTCConnector) {
        item?.p2p.emitRemote(NLPP2PEvents.stop());
      } else if (item instanceof MediaStream) {
        stopRecorder();
      }
    });

    editingList.value.stop();

    const vids = [];

    for (const dvr of dvrs.value) {
      const data = await dvr?.stop();
      vids.push(data);
    }

    vids.forEach((vid, idx) => {
      if (vid == null || !Array.isArray(resultVideo.value)) return;
      const url = window.URL.createObjectURL(vid);
      resultVideo.value[idx - 1] = url;
    });
    await sendInCloud();
    completeRecord();

    const rangeList = editingList.value.exportAsRanges(false);
    await api().uploadRangeList(rangeList);

    store.dispatch('video/getVideos', resultVideo.value);
    store.dispatch('video/getList', editingList.value);
    router.push('/video-editor');

    // currentStep.value = 'finished';
  };

  let isStarting = false;
  const startRecord = async () => {
    if (isStarting === true) return;
    sendMetric('web_start');
    isStarting = true;
    try {
      typeButtonRec.value = 'stop';
      const dt0 = Date.now();
      api().sendDebug('startclick', { dt0: dt0 });
      for (const [index, item] of conns.value.entries()) {
        if (item instanceof NLPWebRTCConnector) {
          item?.p2p.emitRemote(NLPP2PEvents.start(index, dt0));
          item?.p2p.emitRemote(NLPP2PEvents.take(selectedCam.value || 0));
        } else if (item instanceof MediaStream) {
          startLocationRecord();
        }

        // record video
        if (!item) {
          dvrs.value[index] = null;
        } else {
          const dvr = new NLPMediaRecorder('cam' + index);

          if (item instanceof NLPWebRTCConnector) {
            dvr.start(await (item as NLPWebRTCConnector)?.getReceiveStream());
          } else if (item) {
            dvr.start(await item);
          }
          dvrs.value[index] = dvr;
        }
      }
      editingList.value.clear();
      editingList.value.start(selectedCam.value, dt0);
      api().sendDebug('start', { startDt: dt0 });
    } catch (err) {
      console.log('error when start', err);
    } finally {
      isStarting = false;
    }
  };

  const connectionState = ref<string>('');

  const choiceCam = async (cam: number) => {
    indexConnectedCam.value = cam;
    connectionState.value = '';
    const conn = new NLPWebRTCConnector(NLPRtcConnectionSide.Receiver, 'd', `o${cam}`);
    await conn.create(vksOperatorMixer[cam].stream);
    // перенесено в connectVideo
    //conns.value[cam] = conn;
    destroyers.destroyClass(conn, 'destroy', `webrtc-${cam}`);

    conn.on('pc:connectionstatechange', (state) => {
      connectionState.value = state;
    });

    conn.on('rxa:active', () => {
      ++remoteAudioActiveCntr;
      console.log('rxa:active', remoteAudioActiveCntr);
      const tr = localMicStream?.getAudioTracks()[0];
      if (tr !== undefined) tr.enabled = false;
      console.log('tr', tr?.muted);
    });

    conn.on('rxa:inactive', () => {
      if (remoteAudioActiveCntr > 0) --remoteAudioActiveCntr;
      console.log('rxa:inactive', remoteAudioActiveCntr);
      if (remoteAudioActiveCntr === 0) {
        const tr = localMicStream?.getAudioTracks()[0];
        if (tr !== undefined) tr.enabled = true;
        console.log('tr', tr?.muted);
      }
    });

    //eslint-disable-next-line
    // @ts-ignore
    conn.on('open', connectVideo.bind(this, cam, conn));
    conn.p2p.on(
      'event:uploadProgress',
      (camera: number, countFrames: number, totalFrames: number, done: boolean, error: boolean) => {
        store.dispatch('upload/updateRemoteUploadState', {
          camera: camera,
          active: !done,
          current: countFrames,
          total: totalFrames,
          error: error,
        });
      }
    );
  };

  const selectPgm = async (cam: number) => {
    if (selectedCam.value === cam) return;

    if (editingList.value.started) {
      editingList.value.appendEvent({
        dt: Date.now(),
        order: 0,
        type: NLPEditingEventType.TakeVideo,
        camera: cam,
      });
    }

    conns.value.forEach((conn) => {
      if (conn instanceof NLPWebRTCConnector) {
        conn.p2p.emitRemote(NLPP2PEvents.take(cam));
      }
    });

    selectedCam.value = cam;
    isShowPgm.value = true;
  };

  const stopConnection = (cam: number) => {
    console.log('stop');
    if (conns.value[cam] instanceof NLPWebRTCConnector) {
      (conns.value[cam] as NLPWebRTCConnector)?.p2p.emitRemote(NLPP2PEvents.delete());
      /*
      (conns.value[cam] as NLPWebRTCConnector)?.pc?.close();
      (conns.value[cam] as NLPWebRTCConnector).pc = null;
      */
      (conns.value[cam] as NLPWebRTCConnector).destroy();
      isConnected.value[cam] = false;
      conns.value[cam] = undefined;
    } else if (conns.value[cam] instanceof MediaStream) {
      destroyConn();
      conns.value[cam] = undefined;
      isConnectedLocalCam.value = false;
      window.localStorage.removeItem('app.ownCamera');
      window.localStorage.removeItem('app.ownMic');
      window.localStorage.removeItem('app.ownFacing');
    }
    isConnected.value[cam] = false;
    isShowSettings.value = false;
    if (selectedCam.value === cam) selectedCam.value = undefined;
    stopAiTimer();
  };

  async function isCameraAllowed(): Promise<boolean> {
    // сделано так из-за того что PermissionName в ts не имеет вариантов camera, microphone
    //eslint-disable-next-line
    const query = (p: any) => navigator.permissions.query(p);
    const allowVideo = (await query({ name: 'camera' }))?.state === 'granted';
    const allowAudio = (await query({ name: 'microphone' }))?.state === 'granted';
    return allowVideo && allowAudio;
  }

  const completeRecord = async () => {
    isUploadVideo.value = true;

    destroyers.destroyById('report-director');
    const preview: string = await createPreviewVideo(resultVideo.value[(editingList.value?.list[1]?.camera || 1) - 1]);
    const start = editingList.value?.list[0].dt;
    const finished = editingList.value?.list[editingList.value?.list.length - 1].dt;
    store.dispatch('projects/updateProject', { image: preview, duration: finished - start });

    // заливка за 2 прохода: сначала передаем данные пирам
    for (const [index, item] of conns.value.entries()) {
      if (item) {
        const data = editingList.value.exportForPeer(index);

        if (item instanceof NLPWebRTCConnector) {
          item.p2p.emitRemote(NLPP2PEvents.uploadRanges(index, data, totalLength.value[index]));
        }
      }
    }

    // потом заливаем свое
    for (const [index, item] of conns.value.entries()) {
      if (item) {
        if (item instanceof MediaStream) {
          destroyConn();
          const U = new NLPVideoUpload(store.state.projects.currentProjectId);
          await U.open();

          try {
            await U.uploadAll(index);
          } catch (err) {
            window.alert(err);
          }
        }
      }
    }
  };

  const switchLocalCam = async () => {
    if (!isConnectedLocalCam.value) return;
    const cam = conns.value.findIndex((item) => item instanceof MediaStream);
    const st_video = (conns.value[cam] as MediaStream).getVideoTracks()[0];
    const st_audio = (conns.value[cam] as MediaStream).getAudioTracks()[0];
    if (!st_video) return;
    const facing = st_video.getSettings().facingMode;
    const reqFacing = facing === 'user' ? 'environment' : 'user';
    stopConnection(cam);
    indexConnectedCam.value = cam;
    await addOwnCamera({
      videoInput: null,
      audioInput: st_audio?.getSettings().deviceId || '',
      facingMode: reqFacing,
    });
  };

  const vksToggle = async (force = false) => {
    if (localMicStream === null) {
      await getLocalMicTrack();
      vksOperatorMixer.forEach((mixer) => {
        mixer.setupLocalStream(localMicStream);
      });
    }
    if (localMicStream === null) return;

    if (force === false) {
      soundState.value = !soundState.value;
    }

    const tr = localMicStream?.getAudioTracks()[0];
    if (tr !== undefined) tr.enabled = soundState.value;
    //soundState.value = !!vksMixer.toggle();
    //console.log('vks', soundState.value);
  };

  if (soundState.value === true) {
    setTimeout(vksToggle, 200, true);
  }

  return {
    cameraCount,
    addOwnCamera,
    connectVideo,
    destroyAllConnection,
    finishRecord,
    choiceCam,
    isCameraAllowed,
    indexConnectedCam,
    conns,
    selectedCam,
    selectPgm,
    isShowPgm,
    startRecord,
    stopConnection,
    resultVideo,
    dvrs,
    sendInCloud,
    completeRecord,
    connectionState,
    switchLocalCam,
    isConnectedLocalCam,
    isConnected,
    connectedLocalCamera,
    setZoom,
    zoomParams,
    vksToggle,
    vksOperatorMixer,
    vksMixer,
    soundState,
    NLPWebRTCConnector,
  };
}
