
import { defineComponent, ref } from 'vue';
// import Icon from './icon/Icon.vue';
import ModalMenu from '../modals/ModalMenu.vue';
import { sendMetric } from '@/utils/helpers';
import Modal from '@/components/common/Modal.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'BurgerMenu',
  components: { ModalMenu, Modal },
  props: {
    isShowControl: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const isShowMenu = ref(false);
    const route = useRoute();

    const onClickBurger = () => {
      if (!isShowMenu.value) sendMetric('web_burger');
      isShowMenu.value = !isShowMenu.value;
    };
    return {
      onClickBurger,
      isShowMenu,
      route,
    };
  },
});
