
import { defineComponent, onBeforeUnmount, ref, toRefs } from 'vue';
import Icon from '../icon/Icon.vue';
import { useThrottleFn } from '@vueuse/core';
import { sendMetric } from '@/utils/helpers';

export default defineComponent({
  inheritAttrs: false,
  name: 'ButtonNLP',
  props: {
    text: {
      default: '',
      type: String,
    },
    size: {
      default: 'xl',
      type: String,
    },
    type: {
      default: 'primary',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    isMetric: {
      default: false,
      type: Boolean,
    },
    metricName: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const timeout = ref<number | null>(null);
    const { isMetric, metricName } = toRefs(props);

    const click = useThrottleFn(() => {
      if (isMetric.value) sendMetric(metricName.value);
      emit('click');
    }, 500);

    onBeforeUnmount(() => {
      if (timeout.value) clearInterval(timeout.value);
    });

    return {
      click,
    };
  },
  components: { Icon },
});
