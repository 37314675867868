
import { defineComponent, onBeforeUnmount, ref, computed, Ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import Icon from './common/icon/Icon.vue';
import { sendMetric } from '@/utils/helpers';
import { Swiper as SwiperEl } from 'swiper/types';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    Icon,
  },
  name: 'OnboardingVue',
  setup() {
    const isFirstLoad = useStorage('isFirstLoad', true, localStorage);

    onBeforeUnmount(() => {
      isFirstLoad.value = false;
    });

    const skip = () => {
      sendMetric('web_onbording_skip');
      close();
    };

    const createProject = () => {
      sendMetric('web_onbording_create');
      close();
    };

    const close = () => {
      isFirstLoad.value = false;
    };

    const swiper: Ref<SwiperEl | null> = ref(null);

    const onSwiper = (swiperEL: SwiperEl) => {
      swiper.value = swiperEL;
    };

    const previousIndex: Ref<number> = computed(() => {
      return swiper.value?.previousIndex || 0;
    });

    const activeIndex: Ref<number> = computed(() => {
      return swiper.value?.activeIndex || 0;
    });

    const sliderAnimating: Ref<boolean> = computed(() => {
      return swiper.value?.animating || false;
    });

    const isEndSlide: Ref<boolean> = computed(() => {
      return swiper.value?.isEnd || false;
    });

    return {
      close,
      onSwiper,
      swiper,
      previousIndex,
      activeIndex,
      sliderAnimating,
      isEndSlide,
      modules: [Pagination],
      skip,
      createProject,
    };
  },
});
