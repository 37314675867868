import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-feb042b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "home__header" }
const _hoisted_3 = { class: "home__burger-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BurgerMenu = _resolveComponent("BurgerMenu")!
  const _component_ButtonUser = _resolveComponent("ButtonUser")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BurgerMenu)
        ])
      ]),
      _createVNode(_component_ButtonUser, {
        class: "home__button-director",
        text: "Снять видео",
        onClick: _ctx.createProject,
        size: "l",
        icon: "camera",
        isMetric: true,
        metricName: "web_main_create"
      }, null, 8, ["onClick"]),
      _createVNode(_component_ButtonUser, {
        type: "secondary",
        class: "home__button-editor",
        onClick: _ctx.openEditor,
        text: "Видеоредактор",
        size: "l",
        icon: "edit",
        isMetric: true,
        metricName: "web_main_videoedit"
      }, null, 8, ["onClick"])
    ])
  ]))
}