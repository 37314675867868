
import { Ref, defineComponent, onBeforeUnmount, onBeforeUpdate, ref, onMounted, nextTick, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
// use
// import { NLPWebRTCConnector, NLPRtcConnectionSide } from '@/utils/webrtc';
// import { NLPP2PEvents } from '@/utils/p2p-protocol-events';
// use
import { NLPEditingList } from '@/utils/editinglist';
// import { NLPMediaRecorder } from '@/utils/mediarecorder';
import { api } from '@/utils/api';
import { NLPDestroyList } from '@/utils/destroyer';
// import { NLPVideoUpload } from '@/utils/videocapture';
import appEvents from '@/utils/events';
import appSettings from '@/app-settings';
import { NLPVKSDirectorMixer, NLPVKSOperatorMixer } from '@/utils/vks';

import { useCommon } from '@/composables/useCommon';
import { useVideoImage } from '@/composables/useVideoImage';
import { useVideoConnection } from '@/composables/useVideoConnection';

import ButtonUser from '@/components/common/buttons/Button.vue';
import MainVideo from '@/components/video/MainVideo.vue';
import PreviewVideo from '@/components/video/PreviewVideo.vue';
import Icon from '@/components/common/icon/Icon.vue';
import ModalSelectDevice from '@/components/modals/ModalSelectDevice.vue';
// import ButtonRec from '@/components/common/buttons/ButtonRec.vue';
import CircleIconButton from '@/components/common/buttons/CircleIconButton.vue';
import Modal from '@/components/common/Modal.vue';
import RangeInput from '@/components/common/inputs/RangeInput.vue';
import MenuEffects from '@/components/MenuEffects.vue';
import BurgerMenu from '@/components/common/BurgerMenu.vue';
// import SettingsRecord from '@/components/common/SettingsRecord.vue';
// import InteractiveHelper from '@/components/common/InteractiveHelper.vue';
// import { sendMetric } from '@/utils/helpers';

import { useStorage, useSwipe } from '@vueuse/core';
import Controls from '@/components/video/Controls.vue';

export default defineComponent({
  name: 'ProducerView',
  components: {
    Icon,
    // CircleIconButton,
    // ButtonRec,
    ButtonUser,
    MainVideo,
    PreviewVideo,
    ModalSelectDevice,
    Modal,
    // ProgressBar,
    RangeInput,
    // MenuEffects,
    BurgerMenu,
    // SettingsRecord,
    // InteractiveHelper,
    Controls,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const { memory } = useCommon();

    const isShowSettings: Ref<boolean> = ref(false);
    const isShowDelete: Ref<boolean> = ref(false);

    const isShowModalSettings: Ref<boolean> = ref(false);
    const currentStep: Ref<string> = ref('');

    const pgm_video = ref();
    const local_time = ref<number>(0);

    const previevVideo: any = ref([]);
    const isShowModalDevice: Ref<boolean> = ref(false);
    const editingList = ref(new NLPEditingList(store.state.projects.currentProjectId));
    const isShowModalClose = ref<boolean>(false);

    const flipVideo: Ref<Array<boolean>> = ref([]);
    // const aiActive = ref<boolean>(false);
    const isShowInteractiveHelper = ref(false);

    const destroyers: NLPDestroyList = new NLPDestroyList();

    const isShowingHelper = useStorage('isShowingHelper', false, localStorage);

    const producerControls = ref<HTMLElement | null>(null);
    const containerHeight = computed(() => producerControls.value?.offsetHeight);

    const windowWidth = ref(window.innerWidth);
    const windowHeight = ref(window.innerHeight);
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      windowHeight.value = window.innerHeight;
    };

    const mainClass = computed(() => {
      if (windowHeight.value < windowWidth.value) {
        return 'landscape';
      }
      return '';
    });

    const isShowControl = ref(false);
    const isUploadVideo: Ref<boolean> = ref(false);
    const typeButtonRec = ref<string>('start');

    const {
      destroyAllConnection,
      finishRecord,
      choiceCam,
      addOwnCamera: addOwnCam,
      isCameraAllowed,
      indexConnectedCam: connectedCam,
      conns,
      selectedCam,
      selectPgm,
      isShowPgm,
      startRecord,
      stopConnection,
      resultVideo,
      dvrs,
      sendInCloud,
      completeRecord,
      connectionState,
      cameraCount,
      switchLocalCam,
      isConnectedLocalCam,
      isConnected,
      connectedLocalCamera,
      setZoom,
      zoomParams,
      vksOperatorMixer,
      vksMixer,
      vksToggle,
      soundState,
      NLPWebRTCConnector,
    } = useVideoConnection({
      previevVideo,
      flipVideo,
      currentStep,
      stopAiTimer,
      editingList,
      isUploadVideo,
      typeButtonRec,
      isShowSettings,
    });

    for (let cam = 1; cam <= appSettings.cameraCount; ++cam) {
      vksOperatorMixer[cam] = new NLPVKSOperatorMixer(cam);
    }
    // const { lengthY } = useSwipe(producerControls, {
    //   passive: true,
    //   // onSwipeEnd(event: any) {
    //   //   const el: HTMLElement | null = event.target || null;
    //   //   if (el?.closest('.range-input')?.classList.contains('range-input')) return;
    //   //   if (lengthY.value < 0 && containerHeight.value && Math.abs(lengthY.value) / containerHeight.value >= 0.2) {
    //   //     isShowControl.value = false;
    //   //   } else {
    //   //     isShowControl.value = true;
    //   //   }
    //   // },
    // });

    const setPrevievVideo = (el: any) => {
      if (el) {
        previevVideo.value.push(el);
      }
    };

    onBeforeUnmount(() => {
      vksMixer.destroy();
      vksOperatorMixer.forEach((mixer: any) => {
        mixer.destroy();
      });
      vksOperatorMixer.splice(0, vksOperatorMixer.length);
      if (!isUploadVideo.value) destroyAllConnection();

      const idCheckInterval = setInterval(() => {
        if (store.getters['upload/isUploadedAll']) {
          destroyAllConnection();
          clearInterval(idCheckInterval);
        }
      }, 100);
    });

    onBeforeUpdate(() => {
      previevVideo.value = [];
    });

    const stopRecord = async () => {
      stopAiTimer();
      typeButtonRec.value = 'start';
      finishRecord();
    };

    const back = () => {
      currentStep.value = '';
    };

    const close = (isShowWarn: boolean) => {
      if (isShowWarn) {
        isShowModalClose.value = true;
        return;
      }
      if (!isUploadedAll.value) {
        store.dispatch('projects/deleteProject', store.state.projects.currentProjectId);
      }
      router.push('/producer-projects');
    };

    const selectCam = async (cam: number) => {
      isEnlargedCard.value = true;
      choiceCam(cam);
      currentStep.value = 'connection';
    };
    const addOwnCamera = async ({
      videoInput,
      audioInput,
      facingMode,
    }: {
      videoInput: string | null;
      audioInput: string;
      facingMode?: string;
    }) => {
      isShowDelete.value = false;
      isShowModalDevice.value = false;
      addOwnCam({ videoInput, audioInput, facingMode });

      setTimeout(() => {
        isShowInteractiveHelper.value = true;
      }, 4000);
    };
    // use

    function stopAiTimer() {
      destroyers.destroyById('ai-switcher');
      // aiActive.value = false;
    }

    const positionCams = ref(0);

    onMounted(async () => {
      window.addEventListener('resize', handleResize);

      positionCams.value = previevVideo.value[0]?.$el.getBoundingClientRect().bottom;

      destroyers.destroyInterval(
        window.setInterval(() => {
          local_time.value = Date.now();
        }, 1000),
        'update-local-time'
      );

      destroyers.destroyInterval(
        window.setInterval(async () => {
          api().reportDirectorIsOnline();
        }, 2000),
        'report-director'
      );
      if (await isCameraAllowed()) {
        console.log('camera allowed');
        const localCamera = window.localStorage.getItem('app.ownCamera');
        const localMic = window.localStorage.getItem('app.ownMic');
        const localFacing = window.localStorage.getItem('app.ownFacing');
        if ((localCamera || localFacing) && localMic) {
          console.log('add last used camera', localCamera, localFacing, localMic);
          connectedCam.value = 1;
          await addOwnCamera({
            videoInput: localCamera || null,
            audioInput: localMic,
            facingMode: localFacing || undefined,
          });
        } else {
          connectedCam.value = 1;
          showModalDevice();
        }
      }
    });

    // delete
    // const restartStream = async () => {
    //   stopAiTimer();
    //   currentStep.value = '';
    //   resultVideo.value = [];
    //   editingList.value.clear();
    //   await nextTick();

    //   conns.value.forEach((item, index) => {
    //     if (item instanceof NLPWebRTCConnector) {
    //       item?.p2p.emitRemote(NLPP2PEvents.restart(index));
    //     } else if (item instanceof MediaStream) {
    //       createWriter(index);
    //     }
    //     if (!item) return;
    //     connectVideo(index, item);
    //   });
    // };
    // delete

    onBeforeUnmount(async () => {
      window.removeEventListener('resize', handleResize);
      destroyers.destroyAll();
      store.dispatch('upload/clearAll');
    });

    const camsUpload = computed(() => {
      return store.state.upload;
    });

    const { createPreviewVideo } = useVideoImage();

    const isUploadedAll = computed(() => {
      return store.getters['upload/isUploadedAll'];
    });

    const showEditorPage = ref<boolean>(true);

    function aiSwitcherTimerCb(state: { count: number }) {
      if (currentStep.value !== '') return;
      --state.count;
      if (state.count > 0) return;
      state.count = (Math.random() * 4 + 3) | 0;
      for (;;) {
        const cam = (Math.random() * conns.value.length) | 0;
        if (cam === selectedCam.value) continue;
        if (conns.value[cam] === undefined) continue;
        selectPgm(cam);
        break;
      }
    }

    const toggleAi = (value: boolean) => {
      if (cameraCount.value < 2) return;
      if (value) {
        // aiActive.value = true;
        destroyers.destroyInterval(window.setInterval(aiSwitcherTimerCb, 1000, { count: 3 }), 'ai-switcher');
      } else {
        stopAiTimer();
      }
    };

    if (process?.env.NODE_ENV !== 'production') {
      const testRenderCb = async () => {
        const render_res = await api().renderVideo(
          store.state.projects.currentProjectId,
          (current: number, duration: number) => {
            console.log('rendering', current, duration);
          }
        );
        console.log('render res', render_res);
      };
      appEvents.on('test:call-render', testRenderCb);
      destroyers.destroyEvent('test:call-render', testRenderCb);
    }

    const showModalDevice = async () => {
      try {
        if (!(await isCameraAllowed())) {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        isShowModalDevice.value = true;
      } catch (error) {
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'нет доступных устройств' });
      }
    };

    const isEnlargedCard = ref(true);
    const increaseCard = (camera: number) => {
      isEnlargedCard.value = !isEnlargedCard.value;
    };

    const selectCamera = async (cam: number) => {
      if (cam !== selectedCam.value) {
        // isEnlargedCard.value = true;
        await selectPgm(cam);
        return;
      }
      increaseCard(cam);
    };

    const closeDeleteControls = () => {
      setTimeout(() => {
        isShowDelete.value = false;
      }, 0);
    };

    return {
      mainClass,
      isShowModalSettings,
      close,
      currentStep,
      selectedCam,
      connectedCam,
      selectCam,
      previevVideo,
      isConnected,
      setPrevievVideo,
      pgm_video,
      selectPgm,
      isShowPgm,
      isShowSettings,
      addOwnCamera,
      conns,
      isShowModalDevice,
      startRecord,
      stopConnection,
      stopRecord,
      typeButtonRec,
      resultVideo,
      dvrs,
      editingList,
      memory,
      // restartStream,
      sendInCloud,
      completeRecord,
      // uploadList,
      isUploadVideo,
      store,
      // totalLength,
      camsUpload,
      isUploadedAll,
      back,
      isShowModalClose,
      connectionState,
      cameraCount,
      flipVideo,
      switchLocalCam,
      showEditorPage,
      isConnectedLocalCam,
      setZoom,
      zoomParams,
      // aiActive,
      toggleAi,
      showModalDevice,
      isShowDelete,
      positionCams,
      isShowInteractiveHelper,
      isShowingHelper,
      producerControls,
      isShowControl,
      connectedLocalCamera,
      isEnlargedCard,
      increaseCard,
      selectCamera,
      vksToggle,
      soundState,
      NLPWebRTCConnector,
      maxCameraCount: appSettings.cameraCount,
      closeDeleteControls,
    };
  },
});
