
import { defineComponent, onMounted, getCurrentInstance } from 'vue';
import ButtonUser from '@/components/common/buttons/Button.vue';
import { useRouter } from 'vue-router';
import BurgerMenu from '@/components/common/BurgerMenu.vue';
import { api } from '@/utils/api';
import { useStore } from '@/store';

export default defineComponent({
  name: 'RolesView',
  components: {
    ButtonUser,
    BurgerMenu,
  },
  setup() {
    const router = useRouter();

    onMounted(() => {
      const cur = getCurrentInstance();
      cur?.root?.exposed?.checkBrowser();
    });

    const createProject = async () => {
      await store.dispatch('projects/createProject');
      router.push('/director');
    };

    const store = useStore();

    const openEditor = async () => {
      try {
        const { id: idProject } = await api().post('/api/project-demo/1', {});

        router.push(`/video-editor/${idProject}`);
      } catch (error) {
        store.dispatch('modals/getIsShowPreloader', { value: false });
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'Что-то пошло не так' });
      }
    };

    return {
      openEditor,
      createProject,
    };
  },
});
