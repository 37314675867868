
import { defineComponent, nextTick } from 'vue';
import ButtonVue from '@/components/common/buttons/Button.vue';
import Icon from '@/components/common/icon/Icon.vue';
import { useStorage } from '@vueuse/core';

export default defineComponent({
  name: 'InteractiveHelper',
  emits: ['close'],
  setup(props, { emit }) {
    const timeArray: Array<string> = [];

    const getTime = (ms: number) => {
      const minutes = Math.floor(ms / 60000) + '';
      const seconds = Math.floor((ms % 60000) / 1000) + '';
      return `${minutes.padStart(2, '0')} : ${seconds.padStart(2, '0')}`;
    };

    for (let i = 0; i < 41; i++) {
      timeArray.push(getTime(i * 1000));
    }

    const hideVideoEditorTips = useStorage('hideVideoEditorTips', false, localStorage);

    const close = () => {
      hideVideoEditorTips.value = true;
    };

    return {
      close,
      timeArray,
    };
  },
  components: { ButtonVue, Icon },
});
