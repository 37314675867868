
import { Ref, defineComponent, onBeforeUnmount, onMounted, ref, toRefs } from 'vue';
// import Icon from '../icon/Icon.vue';
import { useThrottleFn } from '@vueuse/core';

export default defineComponent({
  inheritAttrs: false,
  name: 'ButtonRec',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    type: {
      default: 'start',
      type: String,
      validator: function (value: string) {
        return ['start', 'stop', 'inactive'].includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const { type } = toRefs(props);

    const animateButton = ref(false);

    const clickBtn = useThrottleFn(() => {
      if (type.value === 'start') return emit('startRecord');
      else if (type.value === 'stop') emit('stopRecord');
      emit('click');
    }, 500);

    const idInterval: Ref<ReturnType<typeof setTimeout> | null> = ref(null);
    const idTimeout: Ref<ReturnType<typeof setTimeout> | null> = ref(null);

    onMounted(() => {
      idInterval.value = setInterval(() => {
        animateButton.value = true;
        idTimeout.value = setTimeout(() => {
          animateButton.value = false;
          if (idTimeout.value) clearTimeout(idTimeout.value);
        }, 4500);
      }, 10000);
    });

    onBeforeUnmount(() => {
      if (idInterval.value) clearInterval(idInterval.value);
      if (idTimeout.value) clearTimeout(idTimeout.value);
    });

    return {
      clickBtn,
      animateButton,
    };
  },
  components: {},
});
