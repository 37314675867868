import { EventEmitter } from 'eventemitter3';

const workletUrl = new URL('./vks-audioworklet.js', import.meta.url);

export class LevelMeterNode extends EventEmitter {
  private _node: AudioWorkletNode | undefined;
  private ctx: BaseAudioContext;
  level = 0;
  constructor(context: BaseAudioContext) {
    super();
    this.ctx = context;
  }

  private async init() {
    if (this._node !== undefined) return;
    await this.ctx.audioWorklet.addModule(workletUrl.href);
    this._node = new AudioWorkletNode(this.ctx, 'level-meter');
    this._node.port.onmessage = (msg) => {
      const wasActive = this.speakerActive;
      this.level = msg.data?.level * 1;
      const nowActive = this.speakerActive;
      if (wasActive !== nowActive) {
        if (wasActive === true) {
          this.emit('inactive', this.level);
          this._node?.port.postMessage({ name: 'setIntervalTime', intervalTime: 1000 });
        } else if (nowActive === true) {
          this.emit('active', this.level);
          this._node?.port.postMessage({ name: 'setIntervalTime', intervalTime: 100 });
        }
      }
    };
  }

  get node(): AudioNode {
    if (this._node === undefined) throw new Error('Node not created');
    return this._node;
  }

  get speakerActive(): boolean {
    return this.level > 0.01;
  }

  async connect(destinationNode: AudioNode, output?: number | undefined, input?: number | undefined) {
    if (this._node === undefined) {
      await this.init();
    }
    if (this._node === undefined) throw new Error('Node not created');
    return this._node.connect(destinationNode, output, input);
  }

  async receive(srcNode: AudioNode) {
    if (this._node === undefined) {
      await this.init();
    }
    if (this._node === undefined) throw new Error('Node not created');
    srcNode.connect(this._node);
  }

  disconnect() {
    if (this._node !== undefined) {
      this._node.port.postMessage(['stop']);
      this._node.port.close();
      this._node.disconnect();
    }
  }
}

export interface LevelMeterCtx {
  ctx: AudioContext;
  input: MediaStreamAudioSourceNode;
  lm: LevelMeterNode;
  destroy: () => void;
}

export function attachLevelMeter(stream: MediaStream): LevelMeterCtx | null {
  const track = stream.getAudioTracks()[0];
  if (track === undefined) return null;

  const ctx = new AudioContext();
  const input = ctx.createMediaStreamSource(stream);
  const lm = new LevelMeterNode(ctx);
  lm.receive(input);

  return {
    ctx,
    input,
    lm,
    destroy: () => {
      lm.disconnect();
      input.disconnect();
      ctx.close();
    },
  };
}

type DestroyFunc = () => void;

export function attachMuteOnRx(rxStream: MediaStream, micStream: MediaStream): DestroyFunc | null {
  const rxTrack = rxStream.getAudioTracks()[0];
  if (rxTrack === undefined) return null;

  const micTrack = micStream.getAudioTracks()[0];
  if (micTrack === undefined) return null;

  const ctx = new AudioContext();
  const input = ctx.createMediaStreamSource(rxStream);
  const lm = new LevelMeterNode(ctx);
  lm.receive(input);

  const destroyer = () => {
    lm.disconnect();
    micTrack.enabled = true;
  };

  lm.on('active', () => {
    micTrack.enabled = false;
    console.log('mute local mic');
  });

  lm.on('inactive', () => {
    micTrack.enabled = true;
    console.log('unmute local mic');
  });

  return destroyer;
}

interface VKSAudioInput {
  gain: GainNode;
  src: MediaStreamAudioSourceNode;
}

export class NLPVKSOperatorMixer {
  private audioCtx: AudioContext = new AudioContext();
  private camera = 0;
  private outputNode: MediaStreamAudioDestinationNode;
  private cameraNodes: Array<MediaStreamAudioSourceNode> = [];
  private localNode: MediaStreamAudioSourceNode | null = null;

  constructor(camera: number) {
    this.camera = camera;
    this.outputNode = this.audioCtx.createMediaStreamDestination();
    /*
    генератор тона на слуай проверки
    const gen = this.audioCtx.createOscillator();
    gen.type = 'square';
    gen.frequency.setValueAtTime(400, this.audioCtx.currentTime);
    gen.connect(this.outputNode);
    gen.start();
    */
  }

  setupLocalStream(stream: MediaStream | null) {
    if (stream === null) return;
    if (this.localNode !== null) this.localNode.disconnect();
    this.localNode = this.audioCtx.createMediaStreamSource(stream);
    this.localNode.connect(this.outputNode);
  }

  get localStreamSet() {
    return this.localNode !== null;
  }

  get stream(): MediaStream | undefined {
    if (this.outputNode === null) return undefined;
    return this.outputNode.stream;
  }

  addCameraStream(camera: number, stream: MediaStream) {
    return;
    if (camera === this.camera) return;
    let node = this.cameraNodes[camera];
    if (node !== undefined) {
      node.disconnect();
    }
    node = this.audioCtx.createMediaStreamSource(stream);
    this.cameraNodes[camera] = node;
    node.connect(this.outputNode);
  }

  destroy() {
    this.localNode?.mediaStream.getAudioTracks().forEach((track) => track.stop());
    this.cameraNodes.forEach((node) => {
      if (node === undefined) return;
      node.mediaStream.getAudioTracks().forEach((track) => track.stop());
    });
    this.audioCtx.close();
  }
}

export class NLPVKSDirectorMixer extends EventEmitter {
  private audioCtx: AudioContext = new AudioContext();
  private inputNodes: Array<VKSAudioInput> = [];
  private currentGain = 1;

  constructor() {
    super();
  }

  addMediaStream(camera: number, stream: MediaStream) {
    if (this.audioCtx === null) throw new Error('vks: no setup() called');
    if (stream.getAudioTracks().length === 0) {
      console.log('vks: addMediaStream called with no audio track');
      return;
    }
    if (this.inputNodes[camera] !== undefined) {
      const inp = this.inputNodes[camera];
      inp.gain.disconnect();
      inp.src.disconnect();
    }
    this.inputNodes[camera] = {
      gain: this.audioCtx.createGain(),
      src: this.audioCtx.createMediaStreamSource(stream),
    };
    const inp = this.inputNodes[camera];
    inp.gain.connect(this.audioCtx.destination);
    inp.gain.gain.setValueAtTime(this.currentGain, this.audioCtx.currentTime);
    inp.src.connect(inp.gain);
  }

  destroy() {
    this.inputNodes.forEach((inp) => {
      const st = inp.src.mediaStream;
      inp.gain.disconnect();
      inp.src.disconnect();
      st.getAudioTracks().forEach((track) => track.stop());
    });
    this.inputNodes.splice(0, this.inputNodes.length);
    this.audioCtx.close();
  }

  private applyCurrentGain() {
    this.inputNodes.forEach((inp) => {
      inp.gain.gain.setValueAtTime(this.currentGain, this.audioCtx.currentTime);
    });
  }

  muteAll() {
    this.currentGain = 0;
    this.applyCurrentGain();
  }

  enableAll() {
    this.currentGain = 1;
    this.applyCurrentGain();
  }

  toggle(): number {
    this.currentGain = Math.round(1 - this.currentGain);
    this.applyCurrentGain();
    return this.currentGain;
  }
}

export class MutedMicStream extends MediaStream {
  private ctx: AudioContext;
  private inputNode: MediaStreamAudioSourceNode;
  private outNode: MediaStreamAudioDestinationNode;
  private gainNode: GainNode;

  constructor(stream: MediaStream) {
    super();
    this.ctx = new AudioContext();
    this.inputNode = this.ctx.createMediaStreamSource(stream);
    this.outNode = this.ctx.createMediaStreamDestination();
    this.gainNode = this.ctx.createGain();
    this.addTrack(this.outNode.stream.getAudioTracks()[0]);
    this.inputNode.connect(this.gainNode);
    this.gainNode.connect(this.outNode);
  }

  destroy() {
    this.inputNode.disconnect();
    this.gainNode.disconnect();
    this.ctx.close();
  }

  setGain(gain: number) {
    this.gainNode.gain.setValueAtTime(gain, this.ctx.currentTime);
  }
}
