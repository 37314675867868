import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalError = _resolveComponent("ModalError")!
  const _component_Preloader = _resolveComponent("Preloader")!
  const _component_Onboarding = _resolveComponent("Onboarding")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_ModalError),
    (_ctx.isShowPreloader)
      ? (_openBlock(), _createBlock(_component_Preloader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isFirstLoad && _ctx.isShowOnboard)
      ? (_openBlock(), _createBlock(_component_Onboarding, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}