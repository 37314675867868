
import { defineComponent, ref, watchEffect, shallowRef } from 'vue';

export default defineComponent({
  name: 'IconMix',
  props: {
    icon: String,
    disabled: { type: Boolean, default: false },
    color: {
      type: String,
      default: 'current',
      validator: function (value: string) {
        return ['white', 'black', 'current'].includes(value);
      },
    },
  },
  setup(props) {
    const svg = shallowRef(null);
    import(`./assets/${props.icon}.svg?component`).then((val) => {
      svg.value = val.default;
    });

    watchEffect(() => {
      import(`./assets/${props.icon}.svg?component`).then((val) => {
        svg.value = val.default;
      });
    });
    return { svg };
  },
});
