
import { computed, defineComponent, onMounted, ref } from 'vue';
import ModalError from '@/components/modals/ModalError.vue';
import { useSize } from '@/composables/useSize';
import Preloader from './components/common/Preloader.vue';
import { useStore } from '@/store';
import Onboarding from '@/components/Onboarding.vue';
import { useStorage } from '@vueuse/core';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: { ModalError, Preloader, Onboarding },
  setup() {
    const { setVarWindowHeight } = useSize();
    const store = useStore();
    const route = useRoute();
    const isShowMadalSupportError = ref(false);
    const isShowBtnShare = ref(false);
    const isSupportVersion = ref(false);

    const updateExists = ref(false);

    const isFirstLoad = useStorage('isFirstLoad', true, localStorage);

    const isShowOnboard = computed(() => {
      return route.path !== '/operator';
    });

    const isShowPreloader = computed(() => {
      return store.state.modals.isShowPreloader;
    });

    const isProdaction = ref(process.env.NODE_ENV === 'production');

    onMounted(() => {
      setVarWindowHeight();

      if ('canShare' in navigator) isShowBtnShare.value = true;
    });

    const shareGoogle = async () => {
      try {
        await navigator.share({ url: `${location.origin}` });
      } catch (err) {
        console.log(err);
      }
    };

    return {
      shareGoogle,
      isShowMadalSupportError,
      isShowBtnShare,
      // listErrorSupport,
      isSupportVersion,
      updateExists,
      isShowPreloader,
      isFirstLoad,
      isShowOnboard,
      isProdaction,
    };
  },
});
