
import { defineComponent, ref } from 'vue';
import Icon from '@/components/common/icon/Icon.vue';
import { api } from '@/utils/api';
import { useRouter } from 'vue-router';
import AppVersion from '@/components/common/AppVersion.vue';
import { useStore } from '@/store';
import { useClipboard } from '@vueuse/core';
import { sendMetric } from '@/utils/helpers';

export default defineComponent({
  components: { Icon, AppVersion },
  name: 'ModalMenu',
  emits: ['close'],
  setup(props, { emit }) {
    const router = useRouter();
    const { copy } = useClipboard();
    const isShowCopyLink = ref(false);

    const menuItems = ref([
      {
        icon: 'main',
        text: 'Главная',
        disabled: false,
        click: () => {
          emit('close');
          router.push('/roles');
        },
      },
      {
        icon: 'catalog',
        text: 'Мои проекты',
        disabled: false,
        click: () => {
          emit('close');
          router.push('/producer-projects');
        },
      },
      {
        icon: 'videocam',
        text: 'Видеоредактор',
        disabled: false,
        click: async () => {
          const { id } = await api().post('/api/project-demo/1', {});
          openEditor(id);
          // router.push('/video-editor');
        },
      },
      {
        icon: 'qr',
        text: 'Сканировать QR код',
        disabled: false,
        click: async () => {
          router.push('/operator');
          // router.push('/video-editor');
        },
      },
      {
        icon: 'share',
        text: 'Поделиться приложением',
        disabled: false,
        click: async () => {
          sendMetric('web_burger_share_app');
          try {
            const shareData = {
              title: 'Снято',
              url: location.origin,
            };

            await navigator.share(shareData);
          } catch (err) {
            copy(location.origin || '');
            // isShowCopyLink.value = true;
            // setTimeout(() => {
            //   isShowCopyLink.value = false;
            // }, 1000);
          }
        },
      },

      {
        icon: 'logout',
        text: 'Выйти из аккаунта',
        disabled: false,
        click: () => {
          api().resetAppAuth();
          router.push('/');
        },
      },
      // { icon: 'catalog', text: 'FAQ', disabled: true },
      // { icon: 'message', text: 'Поддержка', disabled: true },
      // { icon: 'CheckInCircle', text: 'Пользовательское соглашение', disabled: true },
    ]);

    const store = useStore();

    const openEditor = async (idProject: string) => {
      try {
        router.push(`/video-editor/${idProject}`);
      } catch (error) {
        store.dispatch('modals/getIsShowPreloader', { value: false });
        store.dispatch('modals/getIsShowModalError', { value: true, text: 'Что-то пошло не так' });
      }
    };

    return {
      menuItems,
      isShowCopyLink,
    };
  },
});
